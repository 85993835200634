import {createStore} from "vuex";
import axios from "axios";
import moment from "moment";

export default createStore({
    state: {
        widgets: [],
        page: 1,
        total_widgets: 1,
        token: false,
        userName: '',
        plugins: [],
        total_plugins: 1,
    },
    getters: {
        getLogin: (state) => state.token
    },
    actions: {
        async getWidgets({commit}, option) {
            let page = option.page;
            let search = null;
            if(option.search){
                search = option.search;
            }
            try {
                const data = await axios.post('https://admin.ext-crm.ru/api/get.widgets', {
                    page: page,
                    search: search
                });
                let list = [];
                if (data.data?.list.length) {
                    list = [...data.data?.list].map((v) => {
                        return {
                            id: v.id,
                            widget_id: v.widget_id,
                            account_id: v.account_id,
                            widget_code: v.widget_code,
                            subdomain: v.subdomain,
                            widget_paid: v.widget_paid,
                            data_install: moment(v.date_install * 1000).format('DD.MM.YYYY'),
                            data_uninstall: moment(v.date_uninstall * 1000).format('DD.MM.YYYY'),
                            date_demo: moment(v.date_demo * 1000).format('DD.MM.YYYY'),
                            date_paid: moment(v.date_paid * 1000).format('DD.MM.YYYY'),
                            is_paid: v.is_paid,
                            amo_name: v.amo_name,
                            name: v.name,
                            phone: v.phone,
                            email: v.email
                        }
                    });
                }
                commit("SET_WIDGETS", list);
                commit("SET_TOTAL_WIDGETS", data.data.total);
            } catch (error) {
                console.log(error);
            }
        },
        async checkLogin({commit}, option) {
            const request = await fetch('https://admin.ext-crm.ru/api/check.login', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(option.login)
            });
            const data = await request.json();
            if (data?.success && data.success) {
                commit('SET_LOGIN', data.success);
            }
        },
        async getLogin({commit}, option) {
            const request = await fetch('https://admin.ext-crm.ru/api/get.login', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(option)
            });
            const data = await request.json();
            if (data?.success && data.success) {
                commit('SET_USER_NAME', data.success);
            }
        },
        async updateWidget({commit}, option) {
            if (option.widget.date_demo) {
                option.widget.date_demo = moment(option.widget.date_demo, 'DD.MM.YYYY').format('YYYY-MM-DD')
            }
            if (option.widget.date_paid) {
                option.widget.date_paid = moment(option.widget.date_paid, 'DD.MM.YYYY').format('YYYY-MM-DD')
            }
            const request = await fetch('https://admin.ext-crm.ru/api/update.widget', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(option)
            });
            const data = await request.json();
            let newlist = [];
            if (data?.list.length) {
                newlist = [...data?.list].map((v) => {
                    return {
                        id: v.id,
                        widget_id: v.widget_id,
                        account_id: v.account_id,
                        widget_code: v.widget_code,
                        subdomain: v.subdomain,
                        widget_paid: v.widget_paid,
                        data_install: moment(v.date_install * 1000).format('DD.MM.YYYY'),
                        data_uninstall: moment(v.date_uninstall * 1000).format('DD.MM.YYYY'),
                        date_demo: moment(v.date_demo * 1000).format('DD.MM.YYYY'),
                        date_paid: moment(v.date_paid * 1000).format('DD.MM.YYYY'),
                        is_paid: v.is_paid,
                        amo_name: v.amo_name,
                        name: v.name,
                        phone: v.phone,
                        email: v.email
                    }
                });
            }
            commit("SET_WIDGETS", newlist);
            commit("SET_TOTAL_WIDGETS", data.total);
        },
        async getPlugins({commit}, option) {
            let page = option.page;
            let search = null;
            if(option.search){
                search = option.search;
            }
            try {
                const data = await axios.post('https://admin.ext-crm.ru/api/get.plugins', {
                    page: page,
                    search: search
                });
                let pluginList = [];
                if (data.data?.list.length) {
                    pluginList = [...data.data?.list].map((v)=>{
                        return {
                            id: v.id,
                            widget_id: v.widget_id,
                            plugin: v.plugin,
                            date_install: moment(v.date_install * 1000).format('DD.MM.YYYY'),
                            data_uninstall: moment(v.date_uninstall * 1000).format('DD.MM.YYYY'),
                            date_demo: moment(v.date_demo * 1000).format('DD.MM.YYYY'),
                            date_paid: moment(v.date_paid * 1000).format('DD.MM.YYYY'),
                            is_paid: v.is_paid,
                            partner: v.partner,
                            count_upload: v.count_upload,
                            domain: v.domain,
                            name: v.name,
                            phone: v.phone,
                            email: v.email
                        }
                    });
                }
                commit("SET_PLUGINS", pluginList);
                commit("SET_TOTAL_PLUGINS", data.data.total);
            } catch (error) {
                console.log(error);
            }
        },
        async updatePlugin({commit}, option) {
            if (option.plugin.date_demo) {
                option.plugin.date_demo = moment(option.plugin.date_demo, 'DD.MM.YYYY').format('YYYY-MM-DD')
            }
            if (option.plugin.date_paid) {
                option.plugin.date_paid = moment(option.plugin.date_paid, 'DD.MM.YYYY').format('YYYY-MM-DD')
            }
            const request = await fetch('https://admin.ext-crm.ru/api/update.plugin', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(option)
            });
            const data = await request.json();
            let pluginList = [];
            if (data?.list.length) {
                pluginList = [...data?.list].map((v)=>{
                    return {
                        id: v.id,
                        widget_id: v.widget_id,
                        plugin: v.plugin,
                        date_install: moment(v.date_install * 1000).format('DD.MM.YYYY'),
                        date_uninstall: moment(v.date_uninstall * 1000).format('DD.MM.YYYY'),
                        date_demo: moment(v.date_demo * 1000).format('DD.MM.YYYY'),
                        date_paid: moment(v.date_paid * 1000).format('DD.MM.YYYY'),
                        is_paid: v.is_paid,
                        partner: v.partner,
                        count_upload: v.count_upload,
                        domain: v.domain,
                        name: v.name,
                        phone: v.phone,
                        email: v.email
                    }
                });
            }
            commit("SET_PLUGINS", pluginList);
            commit("SET_TOTAL_PLUGINS", data.total);
        },
    },
    mutations: {
        SET_LOGIN(state, token) {
            state.token = token;
        },
        SET_USER_NAME(state, name) {
            state.userName = name;
        },
        SET_WIDGETS(state, widgets) {
            state.widgets = widgets;
        },
        SET_TOTAL_WIDGETS(state, total) {
            state.total_widgets = total;
        },
        SET_PLUGINS(state, pluginList) {
            state.plugins = pluginList;
        },
        SET_TOTAL_PLUGINS(state, total) {
            state.total_plugins = total;
        }
    },
});