import { createApp } from 'vue'
import ElementPlus from "element-plus";
import App from './App.vue'
import {useCookies} from "vue3-cookies";
import "element-plus/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import ru from 'element-plus/dist/locale/ru'
library.add(fas, far, fab)
import router from './components/router/index'
import store from './components/store/store'

createApp(App)
    .use(ElementPlus, {
        locale: ru
    })
    .use(useCookies)
    .use(router)
    .use(store)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
