<template>
    <el-input
            v-model="search"
            placeholder="Поиск"
            :prefix-icon="Search"
            @input="SearchWidget"
    />
    <el-table :data="getPlugins" style="width: 100%" :row-class-name="tableRowClassName">
        <el-table-column label="Плагин" width="100" prop="plugin">
            <template #default="scope">
                {{ this.plugins[scope.row.plugin] }}
            </template>
        </el-table-column>
        <el-table-column label="Домен" width="110" prop="domain"/>
        <el-table-column label="Имя" prop="name"/>
        <el-table-column label="Телефон" width="160">
            <template #default="scope">
                <a class="text-nowrap" :href="`tel:${scope.row.phone}` ">{{ scope.row.phone }}</a>
            </template>
        </el-table-column>
        <el-table-column label="E-mail" prop="email"/>
        <el-table-column label="Дата" prop="date_install" width="130"/>
        <el-table-column label="Тип" width="110">
            <template #default="scope">
                <div v-if="scope.row.is_paid">Подписка</div>
                <div v-else>Демо</div>
            </template>
        </el-table-column>
        <el-table-column align="right" width="80">
            <template #default="scope">
                <el-button size="small" @click="handleEdit(scope.$index, scope.row)">
                    Edit
                </el-button>
            </template>
        </el-table-column>
    </el-table>
    <div class="pagination">
        <el-pagination background layout="prev, pager, next" :total="getTotal" @current-change="setPage"/>
    </div>
    <EditPluginInstall ref="openEdit"/>
</template>

<script lang="js">
import store from "@/components/store/store";
import {onMounted} from "vue";
import {Search} from "@element-plus/icons-vue";
import EditPluginInstall from "@/components/views/modal/EditPluginInstall.vue";

export default {
    name: 'PluginList',
    components: {EditPluginInstall},
    data() {
        return {
            search: '',
            plugins: {'banks': 'Банки','orders': 'Заказы'}
        }
    },
    computed: {
        Search() {
            return Search
        },
        getPlugins() {
            return store.state.plugins;
        },
        getTotal() {
            return store.state.total_plugins;
        }
    },
    setup() {
        onMounted(() => {
            store.dispatch('getPlugins', {page: 1});
        });
    },
    methods: {
        SearchWidget(){
            store.dispatch('getPlugins', {page: 1, search: this.search});
        },
        tableRowClassName(row) {
            if (row.row.is_paid) {
                return 'paid-row'
            } else {
                return 'demo-row'
            }
        },
        handleEdit(index, row) {
            this.$refs.openEdit.openEditPluginInstall(row);
        },
        setPage(val) {
            store.dispatch('getPlugins', {page: val});
        }
    }
}
</script>