<template>
    <el-dialog
            v-model="dialogVisible"
            title="Редактирование аккаунта"
            width="40%"
            :before-close="handleClose">
        <div id="content">
            <el-card shadow="never">
                <template #header>
                    <div class="card-header">
                        <span>Клиент: </span>{{ widget.amo_name }}
                    </div>
                </template>
                <el-row :gutter="20">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">
                    Названия
                </span>
                    <el-input
                            v-model="widget.amo_name"
                            class="w-50 m-2"
                            placeholder="Названия"
                            :prefix-icon="User"
                            @input="changeFrom()"
                    />
                </el-row>
                <el-row :gutter="20">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">
                    ИД аккунт
                </span>
                    <el-input
                            v-model="widget.account_id"
                            class="w-50 m-2"
                            :prefix-icon="More"
                            disabled
                    />
                </el-row>
                <el-row :gutter="20">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">
                    Домен
                </span>
                    <el-input
                            v-model="widget.subdomain"
                            class="w-50 m-2"
                            :prefix-icon="Link"
                            disabled
                    />
                </el-row>
            </el-card>
            <br>
            <el-card shadow="never">
                <template #header>
                    <div class="card-header">
                        <span>Контактные данные</span>
                    </div>
                </template>
                <el-row :gutter="20">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">
                    Имя
                </span>
                    <el-input
                            v-model="widget.name"
                            class="w-50 m-2"
                            placeholder="Имя"
                            :prefix-icon="User"
                            @input="changeFrom()"
                    />
                </el-row>
                <el-row :gutter="20">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">
                    Телефон
                </span>
                    <el-input
                            v-model="widget.phone"
                            class="w-50 m-2"
                            placeholder="Телефон"
                            :prefix-icon="PhoneFilled"
                            @input="changeFrom()"
                    />
                </el-row>
                <el-row :gutter="20">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">
                    E-mail
                </span>
                    <el-input
                            v-model="widget.email"
                            class="w-50 m-2"
                            placeholder="E-mail"
                            :prefix-icon="Message"
                            @input="changeFrom()"
                    />
                </el-row>
            </el-card>
            <br>
            <el-card shadow="never">
                <template #header>
                    <div class="card-header">
                        <span>Настройки виджета</span>
                    </div>
                </template>
                <el-row :gutter="20">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">
                    Дата установки
                </span>
                    <el-input
                            v-model="widget.data_install"
                            class="w-50 m-2"
                            placeholder="Дата установки"
                            :prefix-icon="Calendar"
                            disabled
                    />
                </el-row>
                <el-row :gutter="20">
                <span class="ml-3 w-35 text-gray-600 inline-flex items-center">
                    Платная подписка
                </span>
                    <el-switch
                            v-model="widget.is_paid"
                            class="ml-2"
                            active-text="Платная"
                            inactive-text="Бесплатная"
                            @change="changeFrom"
                            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                    />
                </el-row>
                <el-row :gutter="20" v-if="widget.is_paid">
                    <span class="ml-3 w-35 text-gray-600 inline-flex items-center">
                        Дата окончания подписки
                    </span>
                    <el-date-picker
                            v-model="widget.date_paid"
                            value-format="DD.MM.YYYY"
                            format="DD.MM.YYYY"
                            type="date"
                            class="w-50 m-2"
                            placeholder="Дата"
                            :prefix-icon="Calendar"
                            @change="updatePaidDate"
                    />
                </el-row>
                <el-row :gutter="20" v-else>
                    <span class="ml-3 w-35 text-gray-600 inline-flex items-center">
                        Дата окончания демо периода
                    </span>
                    <el-date-picker
                            v-model="widget.date_demo"
                            value-format="DD.MM.YYYY"
                            format="DD.MM.YYYY"
                            type="date"
                            class="w-50 m-2"
                            placeholder="Дата"
                            :prefix-icon="Calendar"
                            @change="updateDemoDate"
                    />
                </el-row>
            </el-card>
        </div>
        <template #footer>
              <span class="dialog-footer">
                <el-button @click="dialogVisible = false">Отмена</el-button>
                <el-button :disabled="saveEnable" type="primary" @click="saveWidget()">
                  Сохранить
                </el-button>
              </span>
        </template>
    </el-dialog>
</template>

<script>
import {Calendar, Link, Message, More, PhoneFilled, User} from "@element-plus/icons-vue";
import store from "@/components/store/store";

export default {
    name: "EditWidgetInstall",
    computed: {
        Calendar() {
            return Calendar
        },
        Message() {
            return Message
        },
        PhoneFilled() {
            return PhoneFilled
        },
        Link() {
            return Link
        },
        More() {
            return More
        },
        User() {
            return User
        }
    },
    data() {
        return {
            dialogVisible: false,
            saveEnable: true,
            widget: {
                id: 0,
                widget_id: 0,
                account_id: 0,
                widget_code: '',
                subdomain: '',
                widget_paid: false,
                data_install: 0,
                data_uninstall: 0,
                date_demo: 0,
                date_paid: 0,
                is_paid: false,
                amo_name: '',
                name: '',
                phone: '',
                email: '',
                page: 0
            },
            widgetOriginal: null
        }
    },
    methods: {
        handleClose() {
            this.dialogVisible = false;
        },
        openEditWidgetInstall(widget) {
            this.widget = JSON.parse(JSON.stringify(widget));
            this.widgetOriginal = JSON.parse(JSON.stringify(widget));
            this.dialogVisible = true;
        },
        updateDemoDate(date) {
            this.widget.date_demo = date;
            this.changeFrom();
        },
        updatePaidDate(date) {
            this.widget.date_paid = date;
            this.changeFrom();
        },
        saveWidget() {
            this.dialogVisible = false;
            this.saveEnable = false;
            this.widget.page = store.state.page;
            store.dispatch('updateWidget', {
                widget: this.widget
            });
        },
        changeFrom() {
            if(JSON.stringify(this.widget) !== JSON.stringify(this.widgetOriginal)){
                this.saveEnable = !(this.widget.is_paid === false && this.widget.date_demo?.length && this.widget.amo_name.length
                    && this.widget.phone.length && this.widget.email.length && this.widget.name.length ||
                    this.widget.is_paid === true && this.widget.date_paid?.length && this.widget.amo_name.length
                    && this.widget.phone.length && this.widget.email.length && this.widget.name.length);
            }else {
                this.saveEnable = true;
            }
        }
    }
}
</script>

<style scoped>
.card-header {
    text-align: start;
}

.card-header span {
    color: silver;
}

.items-center {
    align-items: center;
}

.inline-flex {
    display: inline-flex;
}

.text-gray-600 {
    --un-text-opacity: 1;
    color: rgba(75, 85, 99, var(--un-text-opacity));
}

.w-35 {
    width: 14.75rem;
}

.ml-3 {
    margin-left: 0.75rem;
}
</style>