import { createWebHistory, createRouter } from "vue-router";
import Widgets from '../views/page/WidgetsList.vue';
import Clients from '../views/page/ClientsList.vue';
import Plugin from '../views/page/PluginList.vue';
import PluginClients from '../views/page/PluginClientsList.vue';

const routes = [
    {
        path: "/",
        name: "Widgets",
        component: Widgets,
    },
    {
        path: "/clients",
        name: "Clients",
        component: Clients,
    },
    {
        path: "/plugin",
        name: "Plugin",
        component: Plugin,
    },
    {
        path: "/plugin/clients",
        name: "PluginClients",
        component: PluginClients,
    },
];

const router = createRouter({
    history: createWebHistory(),
    mode: 'hash',
    routes,
});

export default router;