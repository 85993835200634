<template>
    <el-input
        v-model="search"
        placeholder="Поиск"
        :prefix-icon="Search"
    />
    <el-table :data="getUsers" style="width: 100%">
        <el-table-column label="E-mail" prop="email"/>
        <el-table-column label="Title" prop="name"/>
        <el-table-column align="right">
            <template #default="scope">
                <el-button size="small" @click="handleEdit(scope.$index, scope.row)">
                    Edit
                </el-button>
            </template>
        </el-table-column>
    </el-table>
    <div class="pagination">
        <el-pagination background layout="prev, pager, next" :total="550" @current-change="setPage" />
    </div>
</template>

<script lang="js">
import {onMounted} from 'vue'
import {Search} from "@element-plus/icons-vue";
import store from "@/components/store/store";


export default {
    name: 'ClientsList',
    data() {
        return {
            search: '',
            userList: []
        }
    },
    computed: {
        Search() {
            return Search
        },
        getUsers(){
            return store.state.users;
        }
    },
    setup() {
        onMounted(() => {
            //store.dispatch('fetchUsers', {page: 1});
        });
    },
    methods: {
        handleEdit() {

        },
        setPage (val) {
            store.dispatch('fetchUsers', {page:val});
        }
    }
}
</script>
<style>
  .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
  }
</style>