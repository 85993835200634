<template>
    <div class="common-layout">
        <el-container>
            <el-header v-show="isLogin">
                <el-card shadow="never" body-class="d-flex flex-row justify-content-between">
                    <div class="d-flex">
                        <el-image class="img-logo" :src="require('@/logo.png')" fit="cover" />
                    </div>
                    <div class="d-flex">
                        <el-col :span="4" :offset="20" v-if="isLogin">
                            <el-popconfirm
                                    width="220"
                                    :hide-icon="true"
                                    title="Вы хотите выйти?"
                                    confirm-button-text="Выйти"
                                    cancel-button-text="нет, спасибо"
                                    @confirm="exit()">
                                <template #reference>
                                    <div class="login-name">
                                        {{ geName }}
                                        <el-icon color="red">
                                            <font-awesome-icon icon="fa-solid fa-right-to-bracket"/>
                                        </el-icon>
                                    </div>
                                </template>
                            </el-popconfirm>
                        </el-col>
                    </div>
                </el-card>
            </el-header>
            <el-container class="main-container">
                <el-aside width="260px" v-if="isLogin">
                    <el-card shadow="never">
                        <el-menu
                            default-active="2">
                            <el-sub-menu index="1">
                                <template #title>
                                    <font-awesome-icon style="margin-right: 10px" icon="fa-solid fa-bars-staggered" />
                                    <span>amoCRM</span>
                                </template>
                                <el-menu-item index="1-1">
                                    <font-awesome-icon style="margin-right: 10px" icon="fa-regular fa-circle-down" />
                                    <router-link to="/">Устанвоки</router-link>
                                </el-menu-item>
                                <el-menu-item class="hidden" index="1-2">
                                    <router-link to="/clients">
                                        Пользователи
                                    </router-link>
                                </el-menu-item>
                            </el-sub-menu>
                            <el-sub-menu index="2">
                                <template #title>
                                    <font-awesome-icon style="margin-right: 10px" icon="fa-solid fa-bars-staggered" />
                                    <span>Platrum</span>
                                </template>
                                <el-menu-item index="1-1">
                                    <font-awesome-icon style="margin-right: 10px" icon="fa-regular fa-circle-down" />
                                    <router-link to="/plugin">
                                        Устанвоки
                                    </router-link>
                                </el-menu-item>
                                <el-menu-item class="hidden" index="1-2">
                                    <router-link to="/clients">
                                        Пользователи
                                    </router-link>
                                </el-menu-item>
                            </el-sub-menu>
                        </el-menu>
                        <el-col>
                            <div class="row-item-link hidden">
                                <el-icon class="icon-link">
                                    <Connection/>
                                </el-icon>
                                <router-link to="/">
                            <span class="page-link_item">
                                Амо устанвоки
                            </span>
                                </router-link>
                            </div>
                            <div class="sub-menu hidden">
                                <div class="row-item-link">
                                    <el-icon class="icon-link">
                                        <Connection/>
                                    </el-icon>
                                    <router-link to="/clients">
                                <span class="page-link_item">
                                    Список клиентов
                                </span>
                                    </router-link>
                                </div>
                            </div>
                        </el-col>
                        <el-col>
                            <div class="row-item-link hidden">
                                <el-icon class="icon-link">
                                    <Connection/>
                                </el-icon>
                                <router-link to="/plugin">
                                    <span class="page-link_item">Platrum устанвоки</span>
                                </router-link>
                            </div>
                            <div class="sub-menu hidden">
                                <div class="row-item-link">
                                    <el-icon class="icon-link">
                                        <Connection/>
                                    </el-icon>
                                    <router-link to="/plugin/clients">
                                <span class="page-link_item">
                                    Список клиентов
                                </span>
                                    </router-link>
                                </div>
                            </div>
                        </el-col>
                    </el-card>
                </el-aside>
                <el-main class="el-card-main">
                    <el-card shadow="never">
                        <div v-if="!isLogin">
                            <LoginBlock @callback="loginIn"/>
                        </div>
                        <div v-else>
                            <router-view/>
                        </div>
                    </el-card>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<script>
import {useCookies} from "vue3-cookies";
import LoginBlock from "@/components/LoginBlock.vue";
import store from "@/components/store/store";
import {Connection} from "@element-plus/icons-vue";

export default {
    name: 'App',
    async created() {
        await this.checkLogin();
        await this.getUserLogin();
    },
    computed: {
        geName() {
            return store.state.userName;
        }
    },
    setup() {
        const cookies = useCookies(['token'])
        return {
            cookies,
        }
    },
    components: {
        Connection,
        LoginBlock
    },
    data() {
        return {
            isLogin: false,
            userName: ''
        }
    },
    methods: {
        async getUserLogin() {
            const token = this.cookies.cookies.get('token');
            if (this.cookies.cookies.get('token')) {
                await store.dispatch('getLogin', {token: token});
            }
        },
        loginIn(request) {
            this.cookies.cookies.set('token', request);
            this.checkLogin();
        },
        async checkLogin() {
            const token = this.cookies.cookies.get('token');
            this.isLogin = !!(token && token.length);
            if (this.cookies.cookies.get('token')) {
                await store.dispatch('getLogin', {token: token});
            }
        },
        exit() {
            this.cookies.cookies.remove('token');
            this.isLogin = false;
        }
    }
}
</script>

<style>
.hidden {
    display: none!important;
}
.el-menu {
    border: none!important;
}
.img-logo {
    width: 150px;
}
.img-logo img {
    position: absolute;
    margin-top: 0;
    left: 0;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 10px;
}

#app .common-layout {
    padding: 0 40px;
}

#app .login-name {
    display: flex;
    color: #5f5fff;
    cursor: pointer;
    justify-content: flex-end;
    align-items: center;
}

#app .login-name i {
    margin-left: 5px;
}

.row-item-link {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    font-size: 14px;
}

.page-link_item {
    padding: 0 10px;
    display: flex;
}

.icon-link svg {
    width: 12px;
}

.sub-menu {
    padding-left: 20px;
}

.router-link-exact-active {
    font-weight: bold;
    color: #e83636;
}
.main-container {
    padding: 20px 20px 0;
}
.el-card-main {
    margin-top: -20px;
    margin-right: -20px;
}
</style>
