<template>
    <el-input
        v-model="search"
        placeholder="Поиск"
        :prefix-icon="Search"
        @input="SearchWidget"
    />
    <el-table :data="getWidgets" style="width: 100%" :row-class-name="tableRowClassName">
        <el-table-column label="ID аккаунта" width="110" prop="account_id"/>
        <el-table-column label="Amo аккаунт">
            <template #default="scope">
                <div class="text-nowrap">{{scope.row.amo_name}}</div>
            </template>
        </el-table-column>
        <el-table-column label="Телефон" width="180">
            <template #default="scope">
                <a class="text-nowrap" :href="`tel:${scope.row.phone}` ">{{ scope.row.phone}}</a>
            </template>
        </el-table-column>
        <el-table-column label="Виджет" width="180">
            <template #default="scope">
                {{ this.widget[scope.row.widget_code] }}
            </template>
        </el-table-column>
        <el-table-column label="Дата установки" prop="data_install" width="160"/>
        <el-table-column label="Тип" width="110">
            <template #default="scope">
                <div v-if="scope.row.is_paid">Подписка</div>
                <div v-else>Демо</div>
            </template>
        </el-table-column>
        <el-table-column align="right" width="80">
            <template #default="scope">
                <el-button size="small" @click="handleEdit(scope.$index, scope.row)">
                    Edit
                </el-button>
            </template>
        </el-table-column>
    </el-table>
    <div class="pagination">
        <el-pagination background layout="prev, pager, next" :total="getTotal" @current-change="setPage"/>
    </div>
    <EditWidgetInstall ref="openEdit"/>
</template>

<script lang="js">
import {onMounted} from "vue";
import store from "@/components/store/store";
import {Search} from "@element-plus/icons-vue";
import EditWidgetInstall from "@/components/views/modal/EditWidgetInstall.vue";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Widgets',
    components: {EditWidgetInstall},
    data() {
        return {
            search: '',
            getWidget: [],
            widget: {'amo_dasha_mail':'DashaMail','calctest99':'Калькулятор полей','pellermedia_gong':'Гонг','pellermedia_tips':'База знаний'}
        }
    },
    computed: {
        Search() {
            return Search
        },
        getWidgets() {
            return store.state.widgets;
        },
        getTotal() {
            return store.state.total_widgets;
        }
    },
    setup() {
        onMounted(() => {
            store.dispatch('getWidgets', {page: 1});
        });
    },
    methods: {
        SearchWidget(){
            store.dispatch('getWidgets', {page: 1, search: this.search});
        },
        tableRowClassName(row){
            if (row.row.is_paid) {
                return 'paid-row'
            } else{
                return 'demo-row'
            }
        },
        handleEdit(index, row) {
            this.$refs.openEdit.openEditWidgetInstall(row);
        },
        setPage(val) {
            store.dispatch('getWidgets', {page: val});
        }
    }
}
</script>
<style>
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.demo-row {
    --el-table-tr-bg-color: var( --el-fill-color-lighter);
}
.paid-row{
    --el-table-tr-bg-color: var(--el-color-success-light-9)
}
</style>